table:before {
  content:"";
  position: absolute;
  width: 70%;
  height: 170px;
  background-color: #fbae17;
  z-index: -1;
}
table {
  margin-top: 2.25rem;
  border: 8px solid #fbae17;
  border-radius: 11px;
  border-spacing: 0;
  width: 95%;
}
tr {
  background-color: #f9e0b7;
}
tr td {
  font-family: BDCashbox;
  font-size: 1.5em;
  line-height: 1.5;
  border-bottom: 2px dashed black;
}
.address-input {
  padding-left: 1rem;
  padding-bottom: 0.25rem;
}
input {
  font-family: BDCashbox;
  font-size: 1em;
  outline: none;
  background:none;
  border: none;
  width: 95%;
  padding-left: 0;
}
.postcode {
  border-bottom: none;
}
tr .go {
  background-color: #fbae17;
  width: 90px;
  text-align: center;
  border-bottom: none;
}
table tr:first-child td:first-child {
  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
}
table tr:last-child td:last-child {
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
}

.address-block {
  width: 95%;
  height: 144px;
  background-image: url("../yxa-address-block.png");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}