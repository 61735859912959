@font-face {
    font-family: 'BDCashbox';
    src: url("../../BDCashboxRough.otf");
}

.TextDesc {
    font-family: 'BDCashbox';
    font-size: 1.5em;
}
.disc-area {
    display: flex;
    justify-content: flex-start;
}
.TextDesc-disc {
    font-family: 'BDCashbox';
    font-size: 1.25em;
    /*background-color: orangered;*/
    /*color:white;*/
    /*padding-top: 0.25rem;*/
    /*padding-bottom: 0.5rem;*/
    /*padding-left: 0.5rem;*/
    /*padding-right: 0.5rem;*/
    /*border-radius: 10px;*/
    line-height: 0.75;
    margin-right: 0.75rem;
    margin-top: 0.5rem;
}